import ReactEchartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import {
  BrushComponent,
  DatasetComponent,
  DataZoomComponent,
  DataZoomInsideComponent,
  DataZoomSliderComponent,
  GridComponent,
  GridSimpleComponent,
  LegendComponent,
  MarkLineComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  VisualMapComponent,
  VisualMapContinuousComponent,
  VisualMapPiecewiseComponent,
} from "echarts/components";
import { BarChart } from "echarts/charts";
import "moment/locale/tr";
import { GaugeChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart } from "echarts/charts";
import ImkbEndeksSenetler, { SentimentEndeksLabel } from "../../ata/imkb";
import {
  query,
  collection,
  orderBy,
  limit,
  getDocs,
  where,
} from "firebase/firestore";
import React from "react";
import { fireStore } from "../../../../firebase";
moment.locale("tr");

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  DataZoomComponent,
  DatasetComponent,
  DataZoomSliderComponent,
  GridSimpleComponent,
  DataZoomInsideComponent,
  VisualMapComponent,
  VisualMapContinuousComponent,
  VisualMapPiecewiseComponent,
  BrushComponent,
  LegendComponent,
  MarkLineComponent,
  CanvasRenderer,
  LineChart,
  GaugeChart,
  BarChart,
]);

export const StockBar = (props: any) => {
  const echartsRef = useRef(null);
  const updateCallbacRef = useRef<((newBar: any) => void) | null>(null);
  const updater = (symbol: any, resol: any, updateCallback: any) => {
    updateCallbacRef.current = updateCallback;
  };
  const [options, setOptions] = useState<any>(null);
  const [data, setData] = useState<any>(null);
  const [markers, setMarkers] = useState<any>(null);
  const [lastSentiment, setLastSentiment] = useState<any>(null);
  const [lastPrice, setlastPrice] = useState<any>(null);

  const getChartOptions = (forceMarkers?: any) => {
    if (!data) return;
    return {
      grid: {
        top: 20,
        bottom: 20,
        left: 40,
        right: 30,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: { type: "cross", crossStyle: { color: "#999" } },
      },

      legend: {
        textStyle: {
          color: "#ddd",
        },
      },
      xAxis: [
        {
          type: "category",
          data: data.DateList,
          axisPointer: { type: "shadow" },
          axisLabel: { formatter: "" },
        },
      ],
      yAxis: [
        {
          position: "right",
          type: "value",
          name: "",
          min: -30,
          max: 30,
          interval: 10,
          splitLine: {
            show: true,
            lineStyle: {
              color: "#242b3e",
            },
          },
          axisLabel: {
            color: "#ddd",
          },
          // axisLabel: { formatter: '{value} °C' }
        },
        {
          type: "value",
          name: "",
          scale: true,
          splitLine: {
            show: true,
            lineStyle: {
              color: "#242b3e",
            },
          },
          axisLabel: {
            color: "#ddd",
          },
          // axisLabel: { formatter: '{value} °C' }
        },
      ],
      series: [
        {
          name: "Sentiment Değişim",
          type: "bar",
          tooltip: {
            valueFormatter: function (value: number) {
              return (value as number) + "";
            },
          },
          data: data.ChangeList.map((x: number) => {
            return {
              value: x,
              itemStyle: { color: x < 0 ? "#ff0000" : "#1d9afb" },
            };
          }),
          yAxisIndex: 0,
          barWidth: "85%",
          itemStyle: {
            barBorderRadius: 0,
            shadowColor: "#000",
          },
        },
        {
          name: "Fiyat",
          type: "line",
          tooltip: {
            valueFormatter: function (value: number) {
              return (value as number) + "";
            },
          },
          data: data.PriceList,
          yAxisIndex: 1,
          symbolSize: 3,
          lineStyle: {
            normal: {
              width: 3,
              color: "#3dad4a",
            },
          },
        },
        // {
        //     name: 'Sen. Puan',
        //     type: 'line',
        //     tooltip: { valueFormatter: function (value: number) { return (value as number) + ''; } },
        //     data: data.SentimentList,
        //     yAxisIndex: 0,
        //     symbolSize: 8,
        //     symbol: "diamond",
        //     lineStyle: {
        //         normal: {
        //             width: 3,
        //             color: "#1d9afb",
        //         }
        //     }
        // },
      ],
    };
  };
  const markerFormatter = (value: any) => {
    return parseFloat(value.value).toFixed(2);
  };
  const updateMarkers = () => {
    const newMarkers: any = [];
    if (lastSentiment) {
      newMarkers.push({
        name: "destek",
        yAxis: lastSentiment.value[1],
        lineStyle: {
          width: 0,
        },
        label: {
          formatter: markerFormatter,
          backgroundColor: "#2266f9",
        },
      });
    }
    if (props.marker1 != null && props.marker1 !== "")
      newMarkers.push({
        name: "destek",
        yAxis: props.marker1,
        label: {
          formatter: markerFormatter,
        },
      });
    if (props.marker2 != null && props.marker2 !== "")
      newMarkers.push({
        name: "destek",
        yAxis: props.marker2,
        label: {
          formatter: markerFormatter,
        },
      });
    if (props.marker3 != null && props.marker3 !== "")
      newMarkers.push({
        name: "destek",
        yAxis: props.marker3,
        label: {
          formatter: markerFormatter,
        },
      });
    if (props.marker4 != null && props.marker4 !== "")
      newMarkers.push({
        name: "destek",
        yAxis: props.marker4,
        label: {
          formatter: markerFormatter,
        },
      });

    setMarkers(newMarkers);
    setOptions(getChartOptions());
    setTimeout(() => {
      const echartCurrent: any = echartsRef.current;

      if (echartCurrent) {
        echartCurrent
          .getEchartsInstance()
          .setOption(getChartOptions(newMarkers), true);
      }
    }, 100);
  };
  useEffect(() => {
    updateMarkers();
  }, [
    props.marker1,
    props.marker2,
    props.marker3,
    props.marker4,
    lastSentiment,
  ]);
  useEffect(() => {
    const q = query(
      collection(fireStore, `NS_LINES_${props.list}_1W_P99`),
      where("symbol", "==", props.symbol),
      orderBy("dateTime", "desc"),
      limit(1)
    );

    getDocs(q).then((querySnapshot: any) => {
      if (querySnapshot.docs.length == 0) return;
      const doc = querySnapshot.docs[0];

      const chartData = {
        name: "lines30",
        Index: doc.data().index,
        Symbol: doc.data().symbol,
        Sentiment: doc.data().sentiment,
        Order: doc.data().order,
        ODate: doc.data().openDate,
        OPrice: doc.data().openPrice != "0" ? doc.data().openPrice : "",
        Price: doc.data().price,
        DailyChange: doc.data().dailyChange,
        Vbts: doc.data().vbts,
        First5to10: doc.data().first5to10,
        IsNewFirst5: doc.data().isNewFirst5,
        Viop: doc.data().viop,
        PerDay: doc.data().perDay,
        SentimentList: doc.data().sentimentList,
        PriceList: doc.data().priceList,
        ChangeList: doc.data().changeList,
        ReChangeList: doc.data().changeList.slice().reverse(),
        dateTime: doc.data().dateTime.toDate(),
        Time: doc.data().time ?? 0,
        UpdateTime: moment
          .unix(doc.data().dateTime.seconds)
          .format("DD.MM.yy HH:mm"),
        LineDate: doc.data().dateTime.seconds,
        Indexes: ImkbEndeksSenetler.filter(
          (x) => x.name == doc.data().symbol
        )?.[0]?.val,

        OTT: doc.data().ott,
        Directions: doc.data().directions,
      };
      if(props.onDataLoaded)
        props.onDataLoaded(chartData.Symbol,chartData);
      setData(chartData);
      setOptions(getChartOptions());
    });
  }, []);
  if (!data) return null;

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        backgroundColor: "rgb(19,23,35)",
        borderRadius: 5,
      }}
    >
      <div
        className={
          "weekly-chart-order " +
          (data.Order <= 10 ? "weekly-order-green" : "weekly-order-black")
        }
      >
        {data?.Order}
      </div>
      <div style={{ color: "#fff", textAlign: "center", padding: 3 }}>
        {data?.Symbol}
      </div>
      <ReactEchartsCore
        echarts={echarts}
        ref={echartsRef}
        style={{
          height: `${props.height}px`,
          width: "100%",
          marginBottom: 0,
          backgroundColor: "rgb(19,23,35)",
        }}
        option={options ?? getChartOptions()}
      />
    </div>
  );
};
